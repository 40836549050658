// @import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap");

$body-color: #677788;
$primary-color: #377dff;
$blur-grad: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.01),
    rgb(255, 255, 255) 85%
  ),
  radial-gradient(ellipse at top left, rgba(13, 110, 253, 0.5), transparent 50%),
  radial-gradient(
    ellipse at top right,
    rgba(255, 228, 132, 0.5),
    transparent 50%
  ),
  radial-gradient(
    ellipse at center right,
    rgba(112.520718, 44.062154, 249.437846, 0.5),
    transparent 50%
  ),
  radial-gradient(
    ellipse at center left,
    rgba(214, 51, 132, 0.5),
    transparent 50%
  );
#root {
  overflow-x: hidden !important;
  font-family: "Sen", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
body {
  font-family: "Sen", sans-serif !important;
}

p {
  color: #677788;
}
.menus {
  box-shadow: 0 0.125rem 0.25rem rgba(2, 6, 23, 0.075);
  background: transparent;
  transition: all 0.5s ease;
  position: -webkit-sticky;
  position: sticky;
  z-index: 99;
  width: 100%;
  top: 0;

  &.active {
    background-color: #fff;
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
  }
  &.add-to-scroll-down {
    transform: translateY(-100%);
  }

  header {
    height: 70px;
    max-height: 70px;
    background: transparent;
    border: none;

    .mantine-16nnyi7:hover {
      background-color: transparent;
    }
  }

  & + .footer {
    padding-top: calc(100vh - 100px);
    border: none;
  }
}

.pattern {
  z-index: 1;
  position: relative;
  &::before {
    opacity: 0.45;
    background-image: url(../public/images/square.svg);
    background-position: top;
    bottom: 0;
    content: "";
    height: 312px;
    left: 0;
    -webkit-mask-image: linear-gradient(0deg, transparent 35%, #000 75%);
    mask-image: linear-gradient(0deg, transparent 35%, #000 75%);
    padding: 40px 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
}
.home {
  display: flex;
  align-items: center;
  background-color: transparent;
}
h1 {
  font-weight: 900;
  margin-top: 0;
  font-size: 42px;
  line-height: 1.1;
}
p {
  color: $body-color;
  font-size: 18px;
  font-weight: 500;
  max-width: 510px;
  margin-bottom: 30px;
}

.hero-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
}
.app-screen {
  position: relative;

  img {
    width: 800px !important;
    box-shadow: 0 2.75rem 5.5rem -3.5rem rgb(189 197 209 / 50%),
      0 2rem 4rem -2rem rgb(33 50 91 / 30%),
      inset 0 -0.1875rem 0.3125rem 0 rgb(151 164 175 / 50%);
    background-color: #f8fafd;
    padding: 0.3125rem;
  }
  &.desktop {
    z-index: 2;
    margin-left: -60px;
  }
  &.mobile {
    img {
      width: 200px !important;
      bottom: 33px;
      position: absolute;
      border-radius: 25px;
      left: -35px;
      z-index: 3;
    }
  }
  &.mobile.circle img {
    bottom: -210px;
    background: transparent;
    box-shadow: none;
    left: 60%;
    z-index: 1;
  }
}

.center {
  width: 500px;
  height: 100vh;
  position: absolute;
  inset: 0;
  z-index: 2;
  margin: 0 auto;
  text-align: center;
}

.cta {
  // background: url("../public/images/asset20.svg");
  background-color: rgba(55, 125, 255, 0.1) !important;
  border-radius: 10px;
  padding: 70px 0 0 70px;
  overflow: hidden;

  h1 {
    max-width: 420px;
  }
  .img-wrapper {
    width: max(100%, 512px);
    transform: perspective(1140px) rotateX(7deg) rotateY(-17deg) rotateZ(4deg)
      translate(10px, 25px);

    img {
      box-shadow: 0 2.75rem 5.5rem -3.5rem rgb(189 197 209 / 90%),
        0 2rem 4rem -2rem rgb(33 50 91 / 90%);
    }
  }
  .img-float {
    width: 100%;
    position: absolute;
    top: 0;
    padding: 75px 0 0 53px;
  }
}

.scroll-top svg {
  transform: rotate(-45deg);
}

.post-title {
  display: block;
  font-weight: 700;
  font-size: 18px;
  text-decoration: none;
  margin-top: 10px;
}

// Home Features
.square:after {
  background-image: url("../public/images/square.svg");
  background-position: top;
  bottom: 0;
  content: "";
  height: 312px;
  left: 0;
  -webkit-mask-image: linear-gradient(0deg, transparent 35%, #000 75%);
  mask-image: linear-gradient(0deg, transparent 35%, #000 75%);
  padding: 40px 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

// Pricing Tables
.pricing-list {
  background-color: #fff;

  .select-plan {
    padding: 10px 14px 8px;
    margin: 0 auto 7rem;
    line-height: 1;
    background: #eee;
    display: inline-block;
    border-radius: 35px;
    span {
      color: #677788;
      opacity: 0.7;
      font-weight: 400;
    }

    > div {
      padding-top: 0;
    }
  }
  .item {
    padding: 40px;
    z-index: 2;
    max-width: 430px;
    box-shadow: 0 2.75rem 5.5rem -3.5rem rgb(189 197 209 / 50%),
      0 2rem 4rem -2rem rgb(33 50 91 / 30%);

    &.extend {
      background: transparent;
      box-shadow: none;
    }

    button {
      width: 100%;
    }

    .mantine-List-root {
      list-style: none;
      margin-left: 35px;
      padding: 15px 0;
    }
    h1 {
      font-size: 60px;
      padding: 0.1rem 0 0.5rem;
      sub {
        font-size: 35%;
        bottom: 3px;
        color: #aaa;
      }
    }
    .mantine-List-item {
      white-space: unset !important;
      font-size: 15px;
      padding: 5px 0;

      svg {
        float: left;
        margin-left: -35px;
        margin-top: 3px;
        stroke: #5c7cf9;
      }

      .mantine-List-itemWrapper {
        display: inline;
      }
    }
  }
}
.more-link {
  display: none;
}
// Blog Detail
.blog-details {
  // .post-thumb {
  //     border: 1px solid #eee;
  //     border-radius: 10px;
  // }
  form.comment-form {
    border: 1px solid #eee;
    border-radius: 15px;
    padding: 30px 40px 40px;
  }
  .post-author {
    background: #f0f3f5;
    border-radius: 10px;
    padding: 40px 40px 30px;
    line-height: 24px;
    margin-top: 90px;
  }
  .mantine-TypographyStylesProvider-root {
    .mantine-Text-root,
    p,
    img {
      margin: 15px 0;
    }
  }
}
.mantine-Carousel-indicators {
  bottom: -80px;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
