@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap");
#root {
  overflow-x: hidden !important;
  font-family: "Sen", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
body {
  font-family: "Sen", sans-serif !important;
}

p {
  color: #677788;
}

.menus {
  box-shadow: 0 0.125rem 0.25rem rgba(2, 6, 23, 0.075);
  background: transparent;
  transition: all 0.5s ease;
  position: -webkit-sticky;
  position: sticky;
  z-index: 99;
  width: 100%;
  top: 0;
}
.menus.active {
  background-color: #fff;
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.13);
}
.menus.add-to-scroll-down {
  transform: translateY(-100%);
}
.menus header {
  height: 70px;
  max-height: 70px;
  background: transparent;
  border: none;
}
.menus header .mantine-16nnyi7:hover {
  background-color: transparent;
}
.menus + .footer {
  padding-top: calc(100vh - 100px);
  border: none;
}

.pattern {
  z-index: 1;
  position: relative;
}
.pattern::before {
  opacity: 0.45;
  background-image: url(../public/images/square.svg);
  background-position: top;
  bottom: 0;
  content: "";
  height: 312px;
  left: 0;
  -webkit-mask-image: linear-gradient(0deg, transparent 35%, #000 75%);
  mask-image: linear-gradient(0deg, transparent 35%, #000 75%);
  padding: 40px 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.home {
  display: flex;
  align-items: center;
  background-color: transparent;
}

h1 {
  font-weight: 900;
  margin-top: 0;
  font-size: 42px;
  line-height: 1.1;
}

p {
  color: #677788;
  font-size: 18px;
  font-weight: 500;
  max-width: 510px;
  margin-bottom: 30px;
}

.hero-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
}

.app-screen {
  position: relative;
}
.app-screen img {
  width: 800px !important;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3), inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  background-color: #f8fafd;
  padding: 0.3125rem;
}
.app-screen.desktop {
  z-index: 2;
  margin-left: -60px;
}
.app-screen.mobile img {
  width: 200px !important;
  bottom: 33px;
  position: absolute;
  border-radius: 25px;
  left: -35px;
  z-index: 3;
}
.app-screen.mobile.circle img {
  bottom: -210px;
  background: transparent;
  box-shadow: none;
  left: 60%;
  z-index: 1;
}

.center {
  width: 500px;
  height: 100vh;
  position: absolute;
  inset: 0;
  z-index: 2;
  margin: 0 auto;
  text-align: center;
}

.cta {
  background-color: rgba(55, 125, 255, 0.1) !important;
  border-radius: 10px;
  padding: 70px 0 0 70px;
  overflow: hidden;
}
.cta h1 {
  max-width: 420px;
}
.cta .img-wrapper {
  width: max(100%, 512px);
  transform: perspective(1140px) rotateX(7deg) rotateY(-17deg) rotateZ(4deg) translate(10px, 25px);
}
.cta .img-wrapper img {
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.9), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.9);
}
.cta .img-float {
  width: 100%;
  position: absolute;
  top: 0;
  padding: 75px 0 0 53px;
}

.scroll-top svg {
  transform: rotate(-45deg);
}

.post-title {
  display: block;
  font-weight: 700;
  font-size: 18px;
  text-decoration: none;
  margin-top: 10px;
}

.square:after {
  background-image: url("../public/images/square.svg");
  background-position: top;
  bottom: 0;
  content: "";
  height: 312px;
  left: 0;
  -webkit-mask-image: linear-gradient(0deg, transparent 35%, #000 75%);
  mask-image: linear-gradient(0deg, transparent 35%, #000 75%);
  padding: 40px 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.pricing-list {
  background-color: #fff;
}
.pricing-list .select-plan {
  padding: 10px 14px 8px;
  margin: 0 auto 7rem;
  line-height: 1;
  background: #eee;
  display: inline-block;
  border-radius: 35px;
}
.pricing-list .select-plan span {
  color: #677788;
  opacity: 0.7;
  font-weight: 400;
}
.pricing-list .select-plan > div {
  padding-top: 0;
}
.pricing-list .item {
  padding: 40px;
  z-index: 2;
  max-width: 430px;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3);
}
.pricing-list .item.extend {
  background: transparent;
  box-shadow: none;
}
.pricing-list .item button {
  width: 100%;
}
.pricing-list .item .mantine-List-root {
  list-style: none;
  margin-left: 35px;
  padding: 15px 0;
}
.pricing-list .item h1 {
  font-size: 60px;
  padding: 0.1rem 0 0.5rem;
}
.pricing-list .item h1 sub {
  font-size: 35%;
  bottom: 3px;
  color: #aaa;
}
.pricing-list .item .mantine-List-item {
  white-space: unset !important;
  font-size: 15px;
  padding: 5px 0;
}
.pricing-list .item .mantine-List-item svg {
  float: left;
  margin-left: -35px;
  margin-top: 3px;
  stroke: #5c7cf9;
}
.pricing-list .item .mantine-List-item .mantine-List-itemWrapper {
  display: inline;
}

.more-link {
  display: none;
}

.blog-details form.comment-form {
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 30px 40px 40px;
}
.blog-details .post-author {
  background: #f0f3f5;
  border-radius: 10px;
  padding: 40px 40px 30px;
  line-height: 24px;
  margin-top: 90px;
}
.blog-details .mantine-TypographyStylesProvider-root .mantine-Text-root,
.blog-details .mantine-TypographyStylesProvider-root p,
.blog-details .mantine-TypographyStylesProvider-root img {
  margin: 15px 0;
}

.mantine-Carousel-indicators {
  bottom: -80px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}